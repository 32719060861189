html {
  font-size: 15px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px #F6F9FC;
  border    : solid 3px transparent;
  margin-top: 15px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px lighten($brand-secondary, 25%);
  border    : solid 3px transparent;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px $brand-secondary;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
  flex-wrap : nowrap !important;
  min-height: 50px;

  &-text {
    font-size: 18px;
  }

  .navbar-brand img {
    max-height: 40px;
  }

  .btn {
    height: 40px;
  }
}

.page-wrap {
  min-height: 100vh;
}

.page-footer {
  position: fixed;
  bottom  : 0;
  left    : 0;
  right   : 0;
  width   : 100%;

  &.border {
    border-top: 1px solid #dee2e6;
    box-shadow: 0 -.25rem .75rem rgba(0, 0, 0, .05);
  }
}

.ck.ck-balloon-panel {
  z-index: $zindex-modal !important;
}

.brand-color {
  color: $brand-primary;
}

.brand-primary-bg {
  background-color: $brand-primary;
}

.brand-icon {
  color: $brand-primary;
}

.brand-icon-bg {
  background-color: $brand-primary;
}