// Utilities

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin clearfix() {

  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

// WebKit-style focus
@mixin tab-focus() {
  // Default
  outline       : thin dotted;
  // WebKit
  outline       : 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display     : block;
  margin-left : auto;
  margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width : $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {

  // Firefox 4-18
  &:-moz-placeholder {
    color: $color;
  }

  // Firefox 19+
  &::-moz-placeholder {
    color  : $color;
    opacity: 1; // See https://github.com/twbs/bootstrap/pull/11526
  }

  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $color;
  }

  // Safari and Chrome
  &::-webkit-input-placeholder {
    color: $color;
  }
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow     : hidden;
  text-overflow: ellipsis;
  white-space  : nowrap;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select   : $select;
  -ms-user-select    : $select;
  -o-user-select     : $select;
  user-select        : $select;
}

// Drop shadows
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  box-shadow        : $shadow;
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border) {
  color           : $color;
  background-color: $background;
  border-color    : $border;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color           : $color;
    background-color: darken($background, 8%);
    border-color    : darken($border, 12%);
  }

  &:active,
  &.active {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color    : $border;
    }
  }

  .badge {
    color           : $background;
    background-color: #fff;
  }
}

// Button sizes
// -------------------------
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding      : $padding-vertical $padding-horizontal;
  font-size    : $font-size;
  line-height  : $line-height;
  border-radius: $border-radius;
}