.report-builder {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  min-height     : 100vh;

  &-container {
    width  : 100%;
    padding: 16px;

    &-header {
      display        : flex;
      flex-direction : row;
      justify-content: space-between;
      align-items    : center;

      &-buttons {
        display       : flex;
        flex-direction: column;

        & .btn:not(:first-child) {
          margin-top: 8px;
        }

        @media (min-width: 768px) {
          flex-direction: row;

          & .btn:not(:first-child) {
            margin-top : 0;
            margin-left: 8px;
          }
        }
      }
    }

    &-placeholder {
      height          : 50px;
      border          : 1px dashed gray;
      display         : flex;
      align-items     : center;
      justify-content : center;
      background-color: #DDDDDD;

      span {
        cursor: pointer;
      }
    }

    .report-page {
      &-header {
        padding        : 8px 8px;
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        font-size      : large;
        font-weight    : bold;

        &-number {
          display: none;
        }
      }
    }

    .report-section {
      border : 1px dashed gray;
      padding: 8px;
      margin : 16px 0;

      &-header {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        font-weight    : bold;
      }

      &-content {
        margin-top : 8px;
        padding-top: 8px;
        border-top : 1px solid #DDDDDD;
      }
    }
  }

  .report-toc {
    width        : 240px;
    height       : 100%;
    padding-left : 16px;
    padding-right: 16px;

    &-header {
      display        : flex;
      justify-content: space-between;
      align-items    : baseline;
      padding        : 24px 0;
      border-bottom  : 1px solid #DDDDDD;

      &-icon {
        margin-right: 6px;
      }

      &-text {
        font-weight: bold;
        cursor     : default;
      }

      &-button {
        border-color: #E9E9EB;
        width       : 30px;
      }
    }

    &-page {
      margin-top: 16px;

      &-item {
        display        : flex;
        justify-content: space-between;
        flex-wrap      : nowrap;
        align-items    : baseline;
        margin         : 8px 0;
        cursor         : pointer;

        &-number {
          margin-right: 8px;
          font-weight : bold;
        }

        &-name {
          font-weight: bold;
        }

        &-icon {
          font-size: small;
          cursor   : pointer;
        }
      }
    }

    &-section {
      border-left : 1px solid #DDDDDD;
      padding-left: 8px;

      &-name {
        padding: 4px 8px;
        cursor : default;
      }
    }
  }

  .report-toc-lite {
    height        : 100%;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    padding-top   : 24px;

    &-header {
      display       : flex;
      flex-direction: column;
      align-items   : center;
      border-bottom : 1px solid #E9E9EB;

      &-icon {
        margin-bottom: 15px;
        width        : 30px;
        height       : 30px;
        padding      : 6px;
        border-radius: 6px;
      }

      &-button {
        margin-bottom: 15px;
        border-color : #E9E9EB;
        width        : 30px;
      }
    }

    &-page {
      margin-top    : 15px;
      display       : flex;
      flex-direction: row;
      cursor        : default;

      &-item {
        width     : 30px;
        height    : 28px;
        text-align: center;
      }

      & .active {
        border          : 1px solid #E9E9EB;
        border-radius   : 6px;
        background-color: #E9E9EB;
      }

      &:hover>.report-toc-lite-page-hover {
        display: block;
      }

      &:hover>.report-toc-lite-page-item {
        border          : 1px solid #5A6268;
        border-radius   : 6px;
        background-color: #5A6268 !important;
        color           : #FFFFFF;
      }

      &-hover {
        position    : fixed;
        display     : none;
        margin-left : 30px;
        padding-left: 30px;
        z-index     : $zindex-modal;
      }

      &-content {
        background-color: #FFFFFF;
        border          : 1px solid #E9E9EB;
        border-radius   : 6px;
        padding         : 12px 10px;
        width           : 200px;

        &-header {
          border-bottom : 1px solid #E9E9EB;
          padding-left  : 14px;
          padding-right : 14px;
          padding-bottom: 8px;
          margin-bottom : 8px;
          font-weight   : bold;

          &-number {
            font-size: 17px;
          }

          &-text {
            padding-left: 8px;
          }
        }

        &-section {
          padding: 8px 14px;
          cursor : default;
        }
      }
    }
  }

  .report-builder-toolbar {
    width        : 240px;
    height       : 100%;
    padding-left : 16px;
    padding-right: 16px;
    text-align   : center;

    &-header {
      display        : flex;
      justify-content: flex-start;
      align-items    : baseline;
      padding        : 24px 0;
      border-bottom  : 1px solid #DDDDDD;

      &-text {
        font-weight: bold;
        white-space: nowrap
      }

      &-button {
        border-color: #E9E9EB;
        margin-right: 16px;
        width       : 30px;
      }
    }

    &-components {
      &-heading {
        font-weight: bold;
        padding    : 24px 0;
      }

      .tool {
        border       : 1px dashed gray;
        padding      : 8px;
        margin-bottom: 8px;
      }
    }
  }

  .report-builder-toolbar-lite {
    height        : 100%;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    padding-top   : 24px;

    &-header {
      display       : flex;
      flex-direction: column;
      align-items   : center;

      &-icon {
        padding         : 6px;
        border-radius   : 6px;
        width           : 30px;
        height          : 30px;
        background-color: $brand-primary;
      }

      &-button {
        margin-bottom: 15px;
        border-color : #E9E9EB;
        width        : 30px;
      }
    }
  }

  &-toc-container {
    overflow-x      : hidden;
    transition      : 0.5s;
    background-color: #FFFFFF;
    height          : auto;
    border-right    : 1px solid #E9E9EB;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  &-toolbar-container {
    overflow-x      : hidden;
    transition      : 0.5s;
    background-color: #FFFFFF;
    height          : auto;
    border-left     : 1px solid #E9E9EB;

    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.ck-editor__editable {
  height: 400px;
}