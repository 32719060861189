.custom-btn.btn {
  display         : inline-block;
  text-align      : center;
  vertical-align  : middle;
  cursor          : pointer;
  background-image: none;
  white-space     : nowrap;
  @include button-size(6px, 12px, 15px, 1.428571429, 17px);
  @include user-select(none);

  &:focus {
    @include tab-focus();
  }

  &:hover,
  &:focus {
    text-decoration: none !important;
    box-shadow     : none !important;
  }

  &:active,
  &.active {
    outline         : 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor        : not-allowed;
    pointer-events: none;
    opacity       : .65;
    @include box-shadow(none);
  }

  &.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  }

  &.btn-xxl {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($padding-xxlarge-vertical, $padding-xxlarge-horizontal, $font-size-xxlarge, $line-height-xlarge, $btn-border-radius-xxl);
  }

  &.btn-xl {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($padding-xlarge-vertical, $padding-xlarge-horizontal, $font-size-large, $line-height-large, $btn-border-radius-xl);
  }

  &.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-lg);
  }

  &.btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-sm);
  }

  &.btn-xs {
    @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-xs);
  }
}