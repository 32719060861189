.assessments-component {
  overflow-x: hidden;

  .right-enter {
    transform: translateX(100%);
  }

  .left-enter {
    transform: translateX(-100%);
  }

  .right-enter-active,
  .left-enter-active {
    transform : translateX(0%);
    transition: transform 500ms ease-in-out;
  }

  .right-exit,
  .left-exit {
    transform: translate(0%, -100%);
  }

  .right-exit-active {
    transform : translate(-100%, -100%);
    transition: transform 500ms ease-in-out;
  }

  .left-exit-active {
    transform : translate(100%, -100%);
    transition: transform 500ms ease-in-out;
  }

  .assessments-content {

    & .practice,
    & .singular-content {
      min-height     : 100vh;
      display        : flex;
      flex-direction : column;
      justify-content: center;
      align-content  : center;
    }

    & .scrollable-content {
      margin-top   : 50vh;
      margin-bottom: 50vh;

      & .assessment-container {
        border-bottom: 1px solid #DEDEE1;
      }
    }

    .practice {
      &-text {
        background-color: #DAEDF8;
        color           : #316F8F;
        border          : 1px solid #BDE8F1;
        border-radius   : 8px;
        padding         : 8px 32px;
        font-size       : 18px;
        line-height     : 100%;

        @media (min-width: 768px) {
          font-size: 20px;
        }
      }

      &-question-success {
        position       : absolute;
        width          : 100%;
        height         : 100%;
        top            : 0;
        left           : 0;
        right          : 0;
        bottom         : 0;
        display        : flex;
        justify-content: center;
        align-items    : center;
        z-index        : $zindex-modal;

        &>span {
          color      : $brand-primary;
          opacity    : 0.6;
          font-size  : 88px;
          font-weight: bold;

          @media (min-width: 768px) {
            font-size: 112px;
          }
        }
      }
    }
  }

  .assessment-container {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    min-height     : 200px;
    padding        : 40px 0;
    margin         : 0 40px;

    .assessment-title {
      display        : flex;
      align-items    : center;
      justify-content: center;
      font-size      : x-large;
      font-weight    : 500;
      text-align     : center;
    }

    .assessment-answer {
      display        : flex;
      align-items    : center;
      justify-content: center;
    }

    &.inactive {
      opacity: 0.3;
    }

    .amount-scale-component {
      font-size: 18px;

      .amount-scale {
        display        : flex;
        justify-content: center;
        align-items    : center;

        &-question {
          font-size: 22px;
        }

        &-title {
          position     : relative;
          padding      : 6px 2px;
          margin-bottom: 8px;
          color        : #FFF;

          &::after {
            content     : "";
            position    : absolute;
            top         : 100%;
            left        : 50%;
            margin-left : -7px;
            border-width: 7px;
            border-style: solid;
          }

          &.most {
            background-color: #4CBF34;

            &::after {
              border-color: #4CBF34 transparent transparent transparent;
            }
          }

          &.least {
            background-color: #922492;

            &::after {
              border-color: #922492 transparent transparent transparent;
            }
          }
        }

        &-option-title {
          font-size: 22px;
        }

        &-option-icon {
          font-size: 20px;
        }

        .amount-option {
          display              : flex;
          justify-content      : center;
          align-items          : center;
          border               : 3px solid;
          height               : 55px;
          min-height           : 55px;
          width                : 55px;
          min-width            : 55px;
          border-radius        : 50%;
          -moz-border-radius   : 50%;
          -webkit-border-radius: 50%;
          cursor               : pointer;

          &:focus {
            outline: none;
          }

          .fa-check {
            display: none;
            color  : #4CBF34;
          }

          @media (hover: hover) {
            &:hover .fa-check {
              display: inline-block;
            }
          }

          &.active .fa-check {
            display: inline-block;
            color  : white;
          }

          &.inactive {
            opacity: 0.3;
          }

          &-most {
            border-color: #4CBF34;

            .fa-check {
              color: #4CBF34;
            }

            &.active {
              background-color: #4CBF34;
            }

            &:focus {
              box-shadow: 0 0 0 1px #4CBF34;
            }
          }

          &-least {
            border-color: #922492;

            .fa-check {
              color: #922492;
            }

            &.active {
              background-color: #922492;
            }

            &:focus {
              box-shadow: 0 0 0 1px #922492;
            }
          }
        }
      }
    }

    .dichotomy-scale-component {
      font-size      : 18px;
      display        : flex;
      justify-content: center;

      @media (min-width: 992px) {
        display: unset;
      }

      .dichotomy-scale {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : flex-start;
        text-align     : left;

        @media (min-width: 992px) {
          align-items: center;
          text-align : center;
        }

        &-question {
          font-size  : 22px;
          font-weight: bold;
        }

        &-options {
          width          : 100%;
          display        : flex;
          flex-direction : column;
          justify-content: center;

          @media (min-width: 992px) {
            flex-direction: row;
            margin-top    : 16px;
          }

          .separator {
            border-left: 1px solid #212529;
            margin     : 0 120px;

            @media (max-width: 991px) {
              display: none;
            }
          }

          &>.inactive {
            opacity: 0.3;
          }
        }

        &-option {
          display        : flex;
          flex-direction : row-reverse;
          align-items    : center;
          justify-content: flex-end;
          margin-top     : 32px;

          @media (min-width: 992px) {
            flex-direction : column;
            justify-content: space-between;
            width          : 50%;
            margin-top     : 8px;
            max-width      : 368px;
          }

          &-title {
            font-size: 22px;
          }

          &-checkbox {
            display              : flex;
            justify-content      : center;
            align-items          : center;
            border               : 3px solid #4CBF34;
            height               : 55px;
            min-height           : 55px;
            width                : 55px;
            min-width            : 55px;
            border-radius        : 50%;
            -moz-border-radius   : 50%;
            -webkit-border-radius: 50%;
            cursor               : pointer;
            margin-top           : 8px;

            @media (max-width: 991px) {
              margin-right: 16px;
              margin-top  : 0;
            }

            &:focus {
              outline   : none;
              box-shadow: 0 0 0 1px #4CBF34;
            }

            .fa-check {
              display: none;
              color  : #4CBF34;
            }

            @media (hover: hover) {
              &:hover .fa-check {
                display: inline-block;
              }
            }

            &.active {
              background-color: #4CBF34;

              & .fa-check {
                display: inline-block;
                color  : white;
              }
            }
          }
        }
      }
    }

    .likert-scale-component {
      .likert-scale {
        width          : 100%;
        display        : flex;
        flex-flow      : row nowrap;
        justify-content: center;
        align-items    : center;
        padding-bottom : 10px;

        .likert-option {
          display              : flex;
          justify-content      : center;
          align-items          : center;
          border               : 3px solid #922492;
          height               : 55px;
          min-height           : 55px;
          width                : 55px;
          min-width            : 55px;
          border-radius        : 50%;
          -moz-border-radius   : 50%;
          -webkit-border-radius: 50%;
          cursor               : pointer;

          &:focus {
            outline: none;
          }

          .fa-check {
            display: none;
            color  : #922492;
          }

          @media (hover: hover) {
            &:hover .fa-check {
              display: inline-block;
            }
          }

          &.active .fa-check {
            display: inline-block;
            color  : white;
          }

          &-2,
          &-4 {
            height    : 45px;
            min-height: 45px;
            width     : 45px;
            min-width : 45px;
          }

          &-1,
          &-2 {
            border-color: #922492;

            .fa-check {
              color: #922492;
            }

            &.active {
              background-color: #922492;
            }

            &:focus {
              box-shadow: 0 0 0 1px #922492;
            }
          }

          &-3 {
            height      : 35px;
            min-height  : 35px;
            width       : 35px;
            min-width   : 35px;
            border-color: #858995;

            .fa-check {
              color: #858995;
            }

            &.active {
              background-color: #858995;
            }

            &:focus {
              box-shadow: 0 0 0 1px #858995;
            }
          }

          &-4,
          &-5 {
            border-color: #4CBF34;

            .fa-check {
              color: #4CBF34;
            }

            &.active {
              background-color: #4CBF34;
            }

            &:focus {
              box-shadow: 0 0 0 1px #4CBF34;
            }
          }
        }

        .row-item:not(:first-child) {
          margin-left: 18px;
        }

        @media (max-width: 767px) {
          .row-item:nth-child(2) {
            margin-left: 0;
          }
        }
      }

      .scale-labels {
        @media (max-width: 767px) {
          display: none;
        }
      }

      .scale-labels-mobile {
        display        : flex;
        justify-content: space-between;

        @media (min-width: 768px) {
          display: none;
        }

        span {
          margin-bottom: 0;
        }
      }

      .label-left {
        color: #922492;
      }

      .label-right {
        color: #4CBF34;
      }
    }
  }

  .footer-container {
    padding: 14px;

    .btn {
      display        : flex;
      justify-content: center;
      align-items    : center;
      height         : 40px;
      width          : 30%;

      &.btn-primary {
        width: 65%;
      }

      @media (min-width: 992px) {
        min-width: 200px;
        width    : 200px;

        &.btn-primary {
          min-width: 200px;
          width    : 200px;
        }
      }
    }
  }

  .progress-container {
    margin-top     : 16px;
    padding        : 0 14px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    width          : 100%;

    @media (min-width: 992px) {
      padding: 0 40px;
    }

    .progress {
      height: 6px;

      .progress-bar {
        background-color: #4CBF34;
      }

      &-text {
        margin-top: -20px;
        font-size : 22px;
        padding   : 0 8px;
        color     : $gray-light;
      }
    }

    &-smaller {
      @media (min-width: 992px) {
        display: none;
      }
    }

    &-regular {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

.assessment-type {
  &-container {
    display        : flex;
    flex-direction : column;
    align-content  : center;
    text-decoration: none !important;
    margin-bottom  : 8px;

    &>div {
      display        : flex;
      justify-content: center;
    }

    &.disabled {
      pointer-events: none !important;
    }
  }

  &-name {
    text-align    : center;
    font-size     : 64px;
    font-weight   : bold;
    letter-spacing: .3rem;
    text-transform: uppercase;
  }

  &-label {
    text-align : center;
    font-size  : 32px;
    font-weight: bold;
    color      : #c9cbce;
  }

  &-icon {
    margin-top: 8px;
    font-size : 36px;
  }
}

.assessment-graph {
  .myers-briggs-chart {
    &-row {
      margin        : 16px 0;
      display       : flex;
      flex-direction: column;
    }

    &-percentage {
      display        : flex;
      justify-content: space-between;
      color          : #333A4E;
      line-height    : 100%;
      font-size      : 15px;
    }

    &-bars {
      display: flex;
      margin : 8px 0;

      &>div {
        border-radius: 6px;
      }
    }

    &-labels {
      display        : flex;
      justify-content: space-between;
      text-transform : uppercase;
      color          : #858995;
      line-height    : 100%;
      font-size      : 15px;
      letter-spacing : 0.02em;
    }
  }
}