.home-component {

  @media (min-width: 768px) {
    .home-page-footer {
      display: none !important;
    }
  }

  .home-content {
    min-height     : calc(100vh - 60px);
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    text-align     : center;

    @media (max-width: 767px) {
      &-begin {
        display: none !important;
      }
    }

    @media (min-width: 768px) {
      justify-content: space-evenly;

      &-container {
        padding-bottom: 0 !important;
      }
    }

    @media (min-width: 1024px) {
      padding-left : 160px;
      padding-right: 160px;
    }

    .assessment-name {
      text-transform: uppercase;
      font-weight   : 600;
      font-size     : 3rem;
    }

    &-begin {
      display       : flex;
      flex-direction: column;

      &>.btn {
        margin-bottom: 12px;
      }

      .time-estimate {
        text-align : center;
        font-size  : 11px;
        font-weight: bold;
        color      : $brand-primary;
      }
    }
  }

  .footer-container {
    padding: 14px;

    .btn {
      display        : flex;
      justify-content: center;
      align-items    : center;
      height         : 40px;
      width          : 30%;

      &.btn-primary {
        width: 65%;
      }

      @media (min-width: 992px) {
        min-width: 200px;
        width    : 200px;

        &.btn-primary {
          min-width: 200px;
          width    : 200px;
        }
      }
    }
  }

  .responsive-video-wrapper {
    position      : relative;
    padding-bottom: 56.25%;
    width         : 100%;
    height        : 0;

    iframe {
      position: absolute;
      top     : 0;
      left    : 0;
      width   : 100%;
      height  : 100%;
    }
  }

}

.home-page-footer {
  display        : flex;
  justify-content: center;

  &-container {
    display       : flex;
    flex-direction: column;

    &>.btn {
      margin-bottom: 12px;
    }

    .time-estimate {
      text-align : center;
      font-size  : 11px;
      font-weight: bold;
      color      : $brand-primary;
    }
  }
}