.fade-in {
  animation        : fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation   : fadeIn 1s;
  -o-animation     : fadeIn 1s;
  -ms-animation    : fadeIn 1s;
}

.fade-in-6 {
  animation        : fadeIn6 1s;
  -webkit-animation: fadeIn6 1s;
  -moz-animation   : fadeIn6 1s;
  -o-animation     : fadeIn6 1s;
  -ms-animation    : fadeIn6 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

@-moz-keyframes fadeIn6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

@-webkit-keyframes fadeIn6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

@-o-keyframes fadeIn6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

@-ms-keyframes fadeIn6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}