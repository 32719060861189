.report-builder-list {
  &-controls {
    padding       : 16px 0;
    display       : flex;
    flex-direction: row-reverse;
  }
}

.report-component {
  .report-container {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    min-height     : 100vh;

    .report-content {
      width           : 100%;
      background-color: #F6F9FC;
      transition      : 0.5s;

      @media (max-width: 1023px) {
        margin-left : 0 !important;
        margin-right: 0 !important;
      }

      &-header {
        height  : 280px;
        position: relative;

        &-item {
          position        : absolute;
          top             : 0;
          left            : 0;
          right           : 0;
          bottom          : 0;
          background-image: radial-gradient(circle, rgba(141, 215, 178, 1) 5%, rgba(11, 159, 185, 1) 100%);
          clip-path       : polygon(0 0, 200% 0, 0 100%);
        }
      }

      .report-page {
        position  : relative;
        margin-top: -104px;
        padding   : 0 15px;

        @media (min-width: 992px) {
          padding: 0 40px;
        }

        &-header {
          display        : flex;
          align-items    : center;
          background     : rgba(255, 255, 255, 0.75);
          border         : 1px solid #E9E9EB;
          box-sizing     : border-box;
          box-shadow     : 0px 4px 16px rgba(32, 38, 55, 0.15);
          backdrop-filter: blur(24px);
          border-radius  : 8px;
          padding        : 16px 24px;

          @media (min-width: 1024px) {
            padding: 24px;
          }

          &-heading {
            display    : flex;
            align-items: center;
          }

          &-number {
            font-style    : normal;
            font-weight   : normal;
            font-size     : 16px;
            line-height   : 100%;
            text-transform: uppercase;

            @media (min-width: 1024px) {
              font-size: 24px;
            }
          }

          &-text {
            font-style  : normal;
            font-weight : bold;
            font-size   : 16px;
            line-height : 100%;
            word-break  : break-all;
            padding-left: 20px;

            @media (min-width: 992px) {
              padding-left: 24px;
            }

            @media (min-width: 1024px) {
              font-size   : 36px;
              padding-left: 45px;
            }

            @media (min-width: 1200px) {
              padding-left: 104px;
            }
          }

          &-scroll {
            position       : fixed;
            display        : flex;
            flex-direction : column;
            align-items    : flex-start;
            background     : rgba(255, 255, 255, 0.75);
            box-sizing     : border-box;
            box-shadow     : 0px 4px 16px rgba(32, 38, 55, 0.15);
            backdrop-filter: blur(24px);
            border-radius  : 8px;
            z-index        : $zindex-navbar;

            &-ornament {
              background   : radial-gradient(circle, rgba(141, 215, 178, 1) 5%, rgba(11, 159, 185, 1) 100%);
              border-radius: 5px 5px 0 0;
              width        : 100%;
              height       : 10px;
            }

            &-heading {
              padding: 12px;
            }

            &-number {
              font-style    : normal;
              font-weight   : normal;
              font-size     : 16px;
              line-height   : 100%;
              text-transform: uppercase;
            }

            &-text {
              word-break  : break-all;
              padding-left: 20px;
              font-style  : normal;
              font-weight : bold;
              font-size   : 16px;
              line-height : 100%;
            }
          }
        }

        &-wrapper {
          display        : flex;
          justify-content: center;
        }

        &-content {
          width    : 100%;
          max-width: 720px;
        }
      }

      .report-section {
        padding-top  : 60px;
        scroll-margin: 90px;

        &~.report-section {
          padding-top  : 30px;
          scroll-margin: 120px;
        }

        &-header {
          display        : flex;
          justify-content: space-between;
          align-items    : center;
          padding-bottom : 8px;
          font-style     : normal;
          font-weight    : bold;
          line-height    : 150%;
          font-size      : 20px;


          @media (min-width: 1024px) {
            font-size: 24px;
          }
        }

        &-content {
          padding-top: 8px;

          &.wysiwyg {
            text-align : justify;
            font-style : normal;
            font-weight: normal;
            font-size  : 14px;
            line-height: 150%;

            @media (min-width: 1024px) {
              font-size: 18px;
            }
          }

          .image {
            display        : flex;
            justify-content: center;
            margin-left    : auto;
            margin-right   : auto;

            &.image-inline {
              margin-left : 0;
              margin-right: 0;
            }

            &.image-style-side {
              margin-right: 0;
            }

            &.image-style-align-left {
              justify-content: flex-start;
              margin-left    : 0;
            }

            &.image-style-align-center {
              justify-content: center;
            }

            &.image-style-align-right {
              justify-content: flex-end;
              margin-right   : 0;
            }

            img {
              max-width : 100%;
              max-height: 100%;
            }
          }

          .media>div {
            width: 100%;
          }
        }
      }
    }

    .report-toc {
      width        : 240px;
      padding-left : 16px;
      padding-right: 16px;

      &-header {
        display        : flex;
        justify-content: space-between;
        align-items    : baseline;
        padding        : 24px 0;
        border-bottom  : 1px solid #DDDDDD;

        &-icon {
          margin-right: 6px;
        }

        &-text {
          font-weight: bold;
          cursor     : default;
        }

        &-button {
          border-color: #E9E9EB;
          width       : 30px;
          height      : 30px;
        }
      }

      &-page {
        margin-top: 16px;

        &-item {
          display        : flex;
          justify-content: space-between;
          flex-wrap      : nowrap;
          align-items    : baseline;
          margin         : 8px 0;
          cursor         : pointer;

          &-number {
            margin-right: 8px;
            font-weight : bold;
          }

          &-name {
            font-weight: bold;
          }

          &-icon {
            font-size: small;
            cursor   : pointer;
          }
        }
      }

      &-section {
        border-left : 1px solid #DDDDDD;
        padding-left: 8px;

        &-name {
          padding: 4px 8px;
          cursor : pointer;

          &:hover {
            background-color: #42BFB7;
            border-radius   : 6px;
            color           : #FFFFFF;
          }
        }
      }
    }

    .report-toc-lite {
      display       : flex;
      flex-direction: column;
      align-items   : center;
      padding-top   : 24px;

      &-header {
        display       : flex;
        flex-direction: column;
        align-items   : center;
        border-bottom : 1px solid #E9E9EB;

        &-icon {
          padding         : 6px;
          border-radius   : 6px;
          width           : 30px;
          height          : 30px;
          background-color: $brand-primary;
          margin-bottom   : 15px;
        }

        &-button {
          margin-bottom: 15px;
          border-color : #E9E9EB;
          width        : 30px;
          height       : 30px;
        }
      }

      &-page {
        margin-top    : 15px;
        display       : flex;
        flex-direction: row;
        cursor        : default;

        &-item {
          width     : 30px;
          height    : 30px;
          text-align: center;
        }

        & .active {
          border          : 1px solid #E9E9EB;
          border-radius   : 6px;
          background-color: #E9E9EB;
        }

        &:hover>.report-toc-lite-page-hover {
          display: block;
        }

        &:hover>.report-toc-lite-page-item {
          border          : 1px solid #5A6268;
          border-radius   : 6px;
          background-color: #5A6268 !important;
          color           : #FFFFFF;
        }

        &-hover {
          position    : fixed;
          display     : none;
          margin-left : 30px;
          padding-left: 30px;
          z-index     : $zindex-modal;
        }

        &-content {
          background-color: #FFFFFF;
          border          : 1px solid #E9E9EB;
          border-radius   : 6px;
          padding         : 12px 10px;
          width           : 200px;

          &-header {
            border-bottom : 1px solid #E9E9EB;
            padding-left  : 14px;
            padding-right : 14px;
            padding-bottom: 8px;
            margin-bottom : 8px;
            font-weight   : bold;

            &-number {
              font-size: 17px;
            }

            &-text {
              padding-left: 8px;
            }
          }

          &-section {
            padding: 8px 14px;
            cursor : pointer;

            &:hover {
              background-color: #42BFB7;
              border-radius   : 6px;
              color           : #FFFFFF;
            }
          }
        }
      }
    }

    .report-type {
      width        : 240px;
      padding-left : 16px;
      padding-right: 16px;

      &-header {
        display        : flex;
        justify-content: flex-start;
        align-items    : baseline;
        padding        : 24px 0;
        margin-bottom  : 24px;
        border-bottom  : 1px solid #DDDDDD;

        &-text {
          font-weight: bold;
          white-space: nowrap;
          cursor     : default;
        }

        &-button {
          border-color: #E9E9EB;
          margin-right: 16px;
          width       : 30px;
          height      : 30px;
        }
      }
    }

    .report-type-lite {
      display       : flex;
      flex-direction: column;
      align-items   : center;
      padding-top   : 24px;

      &-header {
        display       : flex;
        flex-direction: column;
        align-items   : center;

        &-icon {
          padding         : 6px;
          border-radius   : 6px;
          width           : 30px;
          height          : 30px;
          background-color: $brand-primary;
        }

        &-button {
          margin-bottom: 15px;
          border-color : #E9E9EB;
          width        : 30px;
          height       : 30px;
        }
      }
    }

    .report-toc-container {
      overflow-x      : hidden;
      overflow-y      : auto;
      display         : none;
      transition      : 0.5s;
      position        : fixed;
      left            : 0;
      top             : 0;
      background-color: #FFFFFF;
      z-index         : $zindex-navbar;
      height          : 100%;
      border-right    : 1px solid #E9E9EB;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    .report-type-container {
      overflow-x      : hidden;
      overflow-y      : auto;
      display         : none;
      transition      : 0.5s;
      position        : fixed;
      right           : 0;
      top             : 0;
      background-color: #FFFFFF;
      z-index         : $zindex-navbar;
      height          : 100%;
      border-left     : 1px solid #E9E9EB;

      @media (min-width: 1024px) {
        display: block;
      }
    }
  }

  .page-footer {
    z-index: $zindex-navbar;
  }

  .footer-container {
    padding: 14px;

    .btn {
      display        : flex;
      justify-content: center;
      align-items    : center;
      height         : 40px;
      width          : 165px;

      @media (min-width: 768px) {
        width: 200px;
      }
    }
  }

  .navmenu-mobile {
    @media (min-width: 1024px) {
      display: none;
    }

    .navbar-brand {
      margin-right: 0;
    }

    .navbar {
      padding-top   : 5px;
      flex-direction: column;
      height        : auto;

      .collapse {
        &.show {
          min-height: calc(100vh - 40px);
          max-height: calc(100vh - 40px);
        }

        &-container {
          min-height: calc(100vh - 100px);
          max-height: calc(100vh - 100px);
          overflow-x: hidden;
          overflow-y: auto;
        }
      }

      .spacer {
        display        : flex;
        justify-content: flex-end;
        border-bottom  : 1px solid #DDDDDD;
        padding-top    : 8px;
        padding-bottom : 8px;
      }
    }

    &-type-button {
      display        : flex;
      justify-content: space-between;
      align-items    : center;
    }

    &-menu-button {
      width: 40px;
    }

    .report-toc {
      width         : 100%;
      display       : flex;
      flex-direction: column;
      font-size     : larger;

      &-header {
        border-bottom : 1px solid #DDDDDD;
        padding-top   : 12px;
        padding-bottom: 12px;

        &-icon {
          margin-right: 6px;
        }

        &-text {
          font-weight: bold;
          cursor     : default;
        }
      }

      &-page {
        margin-top   : 16px;
        padding-right: 12px;

        &-item {
          display        : flex;
          justify-content: space-between;
          flex-wrap      : nowrap;
          align-items    : baseline;
          margin         : 8px 0;
          cursor         : pointer;

          &-number {
            margin-right: 8px;
            font-weight : bold;
          }

          &-name {
            font-weight: bold;
          }

          &-collapse-icon,
          &-expand-icon {
            font-size: small;
            cursor   : pointer;
          }
        }

        &-item.expanded {
          justify-content: flex-start;

          .report-toc-page-item-collapse-icon {
            margin-right: 24px;
          }

          .report-toc-page-item-expand-icon {
            display: none;
          }
        }

        &-item.collapsed {
          .report-toc-page-item-collapse-icon {
            display: none;
          }
        }
      }

      &-section {
        padding-left: 8px;

        &-name {
          padding: 4px 8px;
          cursor : pointer;

          &:hover {
            background-color: #42BFB7;
            border-radius   : 6px;
            color           : #FFFFFF;
          }
        }
      }
    }
  }
}

.assessment-complete-modal {
  overflow: hidden;

  .confetti-container {
    display        : flex;
    justify-content: center;
  }

  .modal-footer {
    justify-content: space-between;
    flex-wrap      : nowrap;

    @media (max-width: 575px) {
      flex-direction: column;

      &>*,
      & .btn {
        width: 100%;
      }
    }
  }
}